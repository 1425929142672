import React, {useState} from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import qs from 'qs';

import { PieChart } from 'react-chartkick';
import 'chart.js';

import { questions } from '../static/questions';
import { buildModel, getQueryParam, cleanData, getFormData } from '../util';
import { Link } from 'gatsby';
import { profileQuestions } from '../static/profileQuestions';
import { FaSync, FaCheck } from 'react-icons/fa';

function ResultsPage(props) {
  const [state, setState] = useState(() => {
    var query = qs.parse(props.location.search, { ignoreQueryPrefix: true }).q;
    var model = buildModel(query);
    return model;
  });

  const submitToFormFlare = async (submissionId = null) => {
    console.log("Submitting to form flare...");
    setState({...state, pending: true});
    let data = await getFormData(state);
    setState({...state, error: !data.isValid, errorFields: data.errorFields});
    if(data.isValid)
    {
      //. submit fresh
      //const completionUrl = 'https://account.formflare.io/ccbd91e4-7673-4127-d059-08d7d0cd9703';
      const completionUrl = 'https://account.formflare.io/d7a6f89b-28ba-4bc5-ab3f-08d9b598fb9e';
      let baseUrl = completionUrl;
      fetch(baseUrl, {
        mode: 'no-cors',
        method: "POST",
        body: data.formData
      }).then(function (res) {
        setState({...state, pending: false, success: true});
      }, function (e) {
        console.log("Submission exception");
        console.error(e);
        setState({...state, pending: false, success: false});
      });
    }
  }

  let formFlareLoadingIcon = (state.pending) ? (<FaSync className="inline ml-2 spin" />) : (state.success) ? (<FaCheck className="inline ml-2"/>) : (<></>);

  const handleStringInputChange = (key, value) => {
    // console.log(`setting ${key}:${value}`);
    setState({...state, [key]: value });
  }

  const piechartData = () => {
    let data = questions.map(section => {
      let now = 0;
      let future = 0;
      section.questions.forEach(question => {
        now += state.hasOwnProperty(question.key + "-n") ? state[question.key + "-n"] : 0;
        future += state.hasOwnProperty(question.key + "-f") ? state[question.key + "-f"] : 0;
      });
      let value = (future - now);
      return [
        section.category,
        value < 1 ? 1 : value
      ]
      //   color: section.color,
      //   title: section.category,
      //   value: value < 1 ? 1 : value
      // }
    });
    return data;
  }

  return (
    <Layout>
      <SEO
        keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
        title="Home"
      />
      <article className="text-left my-2 md:my-6 shadow-lg border border-grey-500 p-6 rounded flex flex-col question-container">
        <div className="flex flex-wrap">
          <h2 className="heading-2 flex-1">What are your biggest challenges?</h2>
          <div>
            <div className="w-full text-right">
              <a onClick={submitToFormFlare} className="cursor-pointer flex md:inline-flex no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md items-center justify-center">Submit Results {formFlareLoadingIcon}</a>
            </div>
          </div>
        </div>
        <div className={["text-red-500", (state.error || false) ? "block" : "hidden"].join(" ")}><b>Required fields missing</b><ul>{(state.errorFields || []).map(o => (<li>{o}</li>))}</ul></div>

        <div className="block mb-4 animate-fade-children-up flex flex-col flex-grow">

        <div className="my-6 flex items-center">
          <div className="w-full md:mr-4 p-8">
            <PieChart data={piechartData()} />
          </div>
          <div className="w-full md:ml-4">
              <p className="font-bold mb-2">Welcome to your Start and Grow Diagnostic Tool.</p>
              <p className="mb-2">This chart shows the areas you are least confident in. We will use this information and our conversation to build your support plan, where we will tailor the programme to help you grow and develop your business.</p>
              <p className="mb-2">We can also use this as a guide to signposting you to other opportunities for training and advice available at the University of Gloucestershire and The Growth Hub.</p>
              <p className="mb-2">If you’ve completed the diagnostic yourself, a member of the team will call you within 3 working days to discuss your results and your next steps.</p>
          </div>
        </div>

        { questions.map((section, s) => (
            <div key={s}>
              <div className="form-label mb-2 mt-6 block underline text-xl">
                <Link className="text-primary hover:underline" to={`/questions/${section.category.toLowerCase()}/1${getQueryParam(state)}`}>
                {section.category}
                </Link>
              </div>
              <div className="table w-full">
                  <div className="table-row-group">
                    <div className="table-row text-gray-700 font-bold">
                      <div className="table-cell px-4 py-2 border-b">#</div>
                      <div className="table-cell px-4 py-2 border-b">Question</div>
                      <div className="table-cell px-4 py-2 border-b text-center">Now</div>
                      <div className="table-cell px-4 py-2 border-b text-center">Future</div>
                    </div>
                    { section.questions.map((question, i) => (
                      <div className="table-row hover:bg-gray-100" key={s + "-" + i}>
                        <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b">
                          <Link className="text-primary hover:underline" to={`/questions/${section.category.toLowerCase()}/${i+1}${getQueryParam(state)}`}>
                            Q&nbsp;{s + 1}.{i + 1}
                          </Link>
                        </div>
                        <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b">{question.label}</div>
                        <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b text-center">{state.hasOwnProperty(question.key + "-n") ? state[question.key + "-n"] : "-" }</div>
                        <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b text-center">{state.hasOwnProperty(question.key + "-f") ? state[question.key + "-f"] : "-" }</div>
                      </div>
                    ))}
                  </div>
                </div>
            </div>
          ))}

          <div>
            <div className="form-label mb-2 mt-6 block underline text-xl"><Link className="text-primary hover:underline" to={`/questions/profile${getQueryParam(state)}`}>Business Profile</Link></div>
            <div className="table w-full">
              <div className="table-row-group">
                <div className="table-row text-gray-700 font-bold">
                  <div className="table-cell px-4 py-2 border-b">#</div>
                  <div className="table-cell px-4 py-2 border-b">Question</div>
                  <div className="table-cell px-4 py-2 border-b text-left">Value</div>
                </div>
                { profileQuestions.map((question, i) => (
                    <div className="table-row hover:bg-gray-100" key={"8-" + i}>
                      <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b">
                        <Link className="text-primary hover:underline" to={`/questions/profile${getQueryParam(state)}`}>
                          Q&nbsp;8.{i + 1}
                        </Link>
                      </div>
                      <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b max-w-sm">{question.label}</div>
                      <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b text-left">{state.hasOwnProperty(question.key) ? state[question.key] : "-" }</div>
                    </div>
                    ))}
                  </div>
                </div>
            </div>
        </div>
        {/* <div>
            <div className="form-label mb-2 mt-6 block underline">Notes</div>
            { questions.map((section, s) => (
            <div className="table w-full">
              <div className="table-row-group">
                <div className="table-row text-gray-700 font-bold">
                  <div className="table-cell pr-4 py-2 border-b">#</div>
                  <div className="table-cell px-4 py-2 border-b">{section.category} Notes</div>
                </div>
                { section.questions.map((question, i) => (
                  <div className="table-row" key={s + "-" + i + "-notes"}>
                    <div className="table-cell text-gray-700 pr-4 py-2 text-sm border-b">
                      <Link className="text-blue-500" to={`/questions/${section.category.toLowerCase()}/${i+1}${getQueryParam(state)}`}>
                        Q&nbsp;{s + 1}.{i + 1}
                      </Link>
                    </div>
                    <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b text-left">{state.hasOwnProperty(question.key + "-notes") ? state[question.key + "-notes"] : "" }</div>
                  </div>
                ))}
                </div>
              </div>
            ))}
            <div className="table w-full">
              <div className="table-row-group">
                <div className="table-row text-gray-700 font-bold">
                  <div className="table-cell pr-4 py-2 border-b">#</div>
                  <div className="table-cell px-4 py-2 border-b">Business Profile Notes</div>
                </div>
                <div className="table-row">
                  <div className="table-cell text-gray-700 pr-4 py-2 text-sm border-b">
                    <Link className="text-blue-500" to={`/questions/profile${getQueryParam(state)}`}>
                      Q&nbsp;8.0
                    </Link>
                  </div>
                  <div className="table-cell text-gray-700 px-4 py-2 text-sm border-b text-left">{ state.notes }</div>
                </div>
                </div>
                </div>
        </div> */}
        <div className="pt-4">
            <div className="w-full text-center">
              <a onClick={submitToFormFlare} className="cursor-pointer flex md:inline-flex no-underline text-white bg-primary hover:bg-secondary transition-bg py-2 px-3 rounded-md items-center justify-center">Submit Results {formFlareLoadingIcon}</a>
            </div>
        </div>
        <div className={["text-red-500", (state.error || false) ? "block" : "hidden"].join(" ")}><b>Required fields missing</b><ul>{(state.errorFields || []).map(o => (<li>{o}</li>))}</ul></div>
      </article>  
    </Layout>
  )
}
export default ResultsPage;